@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  box-sizing: border-box !important;
  font-family: Lato, Roboto, sans-serif;
  color: #1f2937;
}

@layer utilities {
  .v-center {
    @apply flex justify-start items-center;
  }
  .apart {
    @apply flex justify-between relative;
  }
  .center {
    @apply flex justify-center items-center;
  }
  .col-center {
    @apply flex flex-col justify-start items-center;
  }
  .page-center {
    @apply h-[calc(100vh-72px)] flex justify-center items-center;
  }
  .half-page-center {
    @apply h-[60vh] flex justify-center items-center;
  }
  .chart-menu {
    @apply py-1 px-2.5 bg-white center rounded-lg border border-border;
  }
  .page-form {
    @apply px-3 py-4 pb-16 bg-white;
  }
  .card {
    @apply bg-white rounded-lg shadow-sm p-2.5 mb-2 relative;
  }
  .error {
    @apply text-error text-xs mt-1;
  }
  /* Text */
  .page-heading {
    @apply text-xl text-text font-bold;
  }

  .heading {
    @apply text-lg text-text font-bold;
  }

  .title {
    @apply text-base text-text font-bold;
  }

  .title-plain {
    @apply text-base text-text;
  }

  .xs-key {
    @apply text-xxs text-text-lighter;
  }

  .key {
    @apply text-xs text-text-medium;
  }

  .value {
    @apply text-sm text-text font-bold;
  }

  .xs-bold {
    @apply text-xs text-text font-bold;
  }

  .option {
    @apply py-2 border-b hover:bg-primary-negative cursor-pointer;
  }
}

.right-sidebar-shadow {
  /* box-shadow: -4px 0px 2px 1px #d1d5db; */
}

.top-bottom-shadow {
  box-shadow: -1px 1px 8px 1px #d1d5db, 0px -1px 8px 1px #d1d5db;
}

.top-shadow {
  box-shadow: 0 1px 8px 1px #d1d5db;
}

.bottom-shadow {
  /* box-shadow: 0px -1px 8px 1px #d1d5db; */
}

.left-right-shadow {
  box-shadow: -0px 0px 8px 0px #d1d5db;
}

.left-shadow {
  box-shadow: -1px 1px 4px 0px #d1d5db;
}

.right-shadow {
  box-shadow: 1px 1px 4px 0px #d1d5db;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.recharts-cartesian-axis-tick {
  font-size: 10px;
  font-family: Lato, Roboto, sans-serif;
}

.recharts-cartesian-axis-tick-value {
  font-size: 10px;
  font-family: Lato, Roboto, sans-serif;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  /* text-indent: 1px; */
  text-overflow: '';
}

@keyframes spreadanimation {
  0 {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(5);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
}

.user-position-icon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0, 0, 255, 0.3);
  transform: translate(-50%, -50%);
  animation: spreadanimation 1.5s cubic-bezier(0.55, 0.09, 0.68, 0.53) infinite;
}

.leaflet-popup-pane {
  display: none !important;
}

.leaflet-marker-draggable {
  cursor: crosshair !important;
}

.draggable-marker-green {
  @apply bg-green-400;
  @apply border-green-600;
}
.draggable-marker-rest {
  @apply bg-primary;
  @apply border-primary-dark;
}

.line-tooltip {
  font-weight: bold;
  padding: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.line-type-e {
  color: #3388ff;
}

.line-type-eg {
  color: #e7e7e7;
}

.line-type-ra {
  color: #3388ff;
}

.line-type-h {
  color: red;
}

.line-type-ri {
  color: red;
}

.line-type-rv {
  color: #880e4f;
}

.line-type-v {
  color: green;
}

.line-type-s {
  color: yellow;
}

.line-type-f {
  color: yellow;
}

.line-type-line_tool {
  color: orange;
}

.doctemplate-estimate td,
.doctemplate-estimate th {
  padding: 5px;
  font-size: 10px;
}

.doctemplate-estimate h3 {
  font-size: 12px;
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 95% !important;
  height: 100% !important;
}

.ql-toolbar {
  display: flex;
  justify-content: center;
  background-color: #ebecf0;
}

.ql-editor {
  min-height: 480px;
  font-family: inherit;
}

#rte-sender .ql-editor {
  min-height: 40px !important;
  height: auto;
}

#rte-recipient .ql-editor {
  min-height: 40px !important;
  height: auto;
}

#rte-subject .ql-editor {
  min-height: 40px !important;
  height: auto;
}

#rte-message .ql-editor {
  min-height: 360px !important;
  height: auto;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 14px;
  color: #9ca3af;
}

.ql-toolbar.ql-snow {
  position: sticky !important;
  top: 2px;
  z-index: 10 !important;
}

.progress-bar {
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 114, 206);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

.dropdown:hover > .dropdown-content {
  display: block;
}

/* .card {
  position: relative;
  margin-bottom: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.625rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
} */

.flex-1 {
  flex: 1 1;
}

.estimate-tables {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
  margin-top: 0.5rem;
}

.heading {
  font-weight: 700;
  width: 66.666667%;
  top: 2px;
  position: relative;
}

.bold-heading {
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid gray;
  text-align: left;
  background-color: #edf6fe;
  padding: 0.375rem 0.5rem 0.375rem 1rem;
}

.normal-heading {
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid gray;
  text-align: left;
  padding: 0.375rem 0.5rem 0.375rem 1rem;
}

.table {
  width: 100%;
  margin-top: 2.5rem;
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
  font-size: 1rem;
}

.tr-head {
  font-size: 0.875rem;
  line-height: 1.25rem;
  vertical-align: text-top;
  text-align: left;
  height: 2rem;
}

.tr-body {
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-top-width: 1px;
  height: 2.5rem;
}

.tr-total {
  background-color: #edf6fe;
  border-top-width: 1px;
  height: 2.5rem;
}

.th-87 {
  width: 87%;
}

.th-35 {
  width: 35%;
}

.th-26 {
  width: 26%;
}

.th-13 {
  width: 13%;
}

.details-table {
  border-collapse: collapse;
  border-spacing: 0.8rem;
  width: 100%;
}

.even {
  background-color: #f4f5f8;
}

.details-table tr:nth-child(even) {
  background-color: #eff6ff;
}

.details-table td {
  font-size: 14px;
  padding: 8px;
  color: #000;
  font-weight: 400;
}

/* calendar css */
.rbc-today {
  background-color: #eff6ff !important;
}

.rbc-month-row {
  overflow: visible !important;
}

.rbc-event-content {
  overflow: visible !important;
}

.bg-event-primary {
  background-color: #095dcf !important;
  border: 1px solid white !important;
  overflow: visible !important;
}

.rbc-label {
  font-size: 10px;
}

.rbc-time-slot > .rbc-label {
  color: #718096;
}

.rbc-off-range-bg {
  background-color: white !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-time-header-cell {
  height: 130%;
}

span[role='columnheader'] {
  color: #71717a;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.custom-header {
  display: flex;
  flex-direction: column;
  padding: 0.5rem !important;
}

.custom-header-date {
  font-size: x-large;
  font-weight: 500;
}

/* tooltip */
.custom-tooltip {
  z-index: 99;
  width: 200px !important;
  max-width: 200px !important;
  word-wrap: break-word;
}

.A4 {
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  padding: 10px 25px;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  box-sizing: border-box;
  font-size: 12pt;
}

/* For Quill Editor Fonts  */

.montserrat-font .ql-editor * {
  font-family: 'Montserrat';
}

.roboto-font .ql-editor * {
  font-family: 'Roboto';
}
.pt_sans-font .ql-editor * {
  font-family: 'PT Sans';
}
.poppins-font .ql-editor * {
  font-family: 'Poppins';
}

.lato-font .ql-editor * {
  font-family: 'Lato';
}

.open_sans-font .ql-editor * {
  font-family: 'Open Sans';
}

.ReactCrop__crop-selection .ReactCrop__drag-handle:after {
  height: 24px;
  width: 24px;
}

.slider-track {
  height: 8px;
  border-radius: 4px;
  position: relative;
  background: #ddd;
}

.slider-track.slider-track-0 {
  background: #4ade80 !important;
}

.data-source-blot {
  background-color: #dbeafe;
  margin-right: 4px;
}

.dst > .ql-toolbar {
  background-color: white !important;
}

.estimate-photos-table {
  border-radius: 3px;
  font-weight: 400;
  width: 100%;
}

.t-image {
  padding: 2px 2px;
  height: 225px;
  max-height: 225px;
  min-height: 225px;
  width: 310px;
  overflow: hidden;
  object-fit: contain;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.map-custom-tooltip {
  font-size: 12px;
  font-weight: bold;
  color: #333;
  /* background: none; 
  border: none;
  box-shadow: none; */
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}
